import React, { Component } from 'react'
import { Link } from 'gatsby'
import DemoImage1 from './Work/DemoImage1'
import DemoImage2 from './Work/DemoImage2'
import DemoImage3 from './Work/DemoImage3'
import DemoImage4 from './Work/DemoImage4'
// import DemoImage5 from './Work/DemoImage5'

export default class Project extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-md-8 project-brief">
                    <div>
                        <Link to={this.props.projectDemoLink}>
                            <h3 className="ck-text-title-project">
                                {this.props.title}
                            </h3>
                        </Link>
                    </div>
                    <div>
                        <p className="ck-text-timespan-project">
                            {this.props.timespan}
                        </p>
                    </div>
                    <div>
                        <h4 className="ck-text-description-project">
                            {this.props.tools}
                        </h4>
                    </div>
                </div>
                <div className="col-md-4 ck-brief-project">
                    <DemoImage1 className={`ck-thumbnail-div`} children={this.props.thumbnailImage} />
                    <DemoImage2 className={`ck-thumbnail-div`} children={this.props.thumbnailImage} />
                    <DemoImage3 className={`ck-thumbnail-div`} children={this.props.thumbnailImage} />
                    <DemoImage4 className={`ck-thumbnail-div`} children={this.props.thumbnailImage} />
                    {/* <DemoImage5 className={`ck-thumbnail-div`} children={this.props.thumbnailImage} /> */}
                </div>
            </React.Fragment>
        )
    }
}