import React, { Fragment } from "react";
import Img from "gatsby-image";
import { StaticQuery, graphql } from "gatsby";

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Fragment>
          <a href="https://ck-react-youtube.netlify.app/" target="_blank" rel="noreferrer">
            <Img fluid={data.bgImg4.childImageSharp.fluid} alt="https://ck-react-youtube.netlify.app/" className={`ck-thumbnail-image ${children==='bgImg4'?'':'hidden'}`} />
          </a>
        </Fragment>
      )}
    />
  );
};

const query = graphql`
  query {
    bgImg4: file(relativePath: { eq: "home4.png" }) {
      childImageSharp {
        fluid(maxWidth: 504) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Layout;