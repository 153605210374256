import React, { Component } from 'react'
import Project from '../Project'

class WorkSection extends Component {
    render() {
        return(
            <div className="container ck-container-work">
                <h2 className="ck-heading-work">
                    Projects
                </h2>
                <div className="row">
                    <Project
                        title="React Messenger"
                        timespan="Sep, 2020 - Nov, 2020"
                        tools="Material UI | ReactJS | ReduxJS | Firebase"
                        thumbnailImage="bgImg1"
                        projectDemoLink="/project/ck-react-messenger"
                    />
                    <Project
                        title="Gatsby Personal Website"
                        timespan="Jul, 2020"
                        tools="Bootstrap | CSS | Gatsby | GraphQL"
                        thumbnailImage="bgImg2"
                        projectDemoLink="/project/chillingking"
                    />
                    <Project
                        title="Gatsby E-Commerce"
                        timespan="Apr, 2020 - Jun, 2020"
                        tools="CSS | Gatsby | GraphQL | Snipcart"
                        thumbnailImage="bgImg3"
                        projectDemoLink="/project/ck-gatsby-ecommerce"
                    />
                    <Project
                        title="React YouTube"
                        timespan="Jul, 2019 - Aug, 2019"
                        tools="CSS | ReactJS | YouTube API"
                        thumbnailImage="bgImg4"
                        projectDemoLink="/project/ck-react-youtube"
                    />
                    <Project
                        title="Blockchain"
                        timespan="Jun, 2019"
                        tools="Solidity | Python | Flask | Ganache"
                        thumbnailImage="bgImg5"
                        projectDemoLink="/project/blockchain"
                    />
                </div>
            </div>
        )
    }
}

export default WorkSection