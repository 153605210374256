import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import Header from '../components/Header'
import WorkSection from '../components/Work/WorkSection'
import Footer from '../components/Footer'

class Work extends Component {
    render() {
        return(
            <React.Fragment>
                <Helmet
                    htmlAttributes={{
                        lang: 'en',
                    }}
                >
                    <meta charSet="utf-8" />
                    <title>Amol Garhwal | Work</title>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;800;900&display=swap"/>
                    <meta name="description"
                    content="This page describes my work experience, internships, projects and contributions." />
                </Helmet>
                <Header selected="work" />
                <WorkSection />
                <Footer />
            </React.Fragment>
        )
    }
}

export default Work